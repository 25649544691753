.members {
    @include px(8px);
    text-align: center;
    align-self: flex-start;

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*2);
        margin: 0 0 86px;
    }

    @include media-breakpoint-down(md) {
        @include fw(216px);
    }
}

.members__picture {
    .img-fluid {
        border-radius: 50%;
    }
}

.members__title {
    @include fs-lh(24px,30px);
    font-family: $font-family_title;
    margin: -20px 0 10px;
}

.members__desc {
    line-height: 21px;
    letter-spacing: 0.03em;
}