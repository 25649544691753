.menu {
  &.menu_top {
    //display: none;
    @include media-breakpoint-down(md) {
      @include pos(0,0,fixed);
      @include size(344px,600px);
      padding: 68px 24px 68px 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      transform: translateX(-100%);
      transition: all 0.5s ease-in-out;
      overflow: hidden;
      background: #333;
      border-radius: 0 0 16px 0;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    }

    .close {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &.menu_bottom {
    text-align: center;

    .menu__item {
      @include media-breakpoint-down(md) {
        margin-right: 24px;
      }
    }
  }

  &.visible {
    transform: translateX(0);
  }
}

.menu__wrapper {
  @extend %zi-2;
}

.menu__item {
  @include before;
  @include font(12px,500);
  @include inline-block(middle);
  @include offset(0 36px 0 0,4px 8px);
  line-height: $line-height_content;
  text-transform: uppercase;
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,0);

  &:before {
    @include pos(50%,-16px);
    @include size(2px,16px);
    margin: -8px 0 0;
    background: #fff;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }

  &.menu__item_top {
    @include media-breakpoint-down(md) {
      @include offset(0 0 18px,0);
      display: block;
      border: 0;
      border-radius: 0;
  
      &:before {
        display: none;
      }
    }
  }

  &.menu__item_current {
    font-weight: 700;
    color: $color__brand;
    border-color: $color__brand;
  }
}

.menu__footer {
  @extend %zi-1;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  @include media-breakpoint-down(md) {
    height: 489px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 50px;
  }
}