.grid {
    @extend %d-flex;

    &.grid_maxpx {
        @include media-breakpoint-up(md) {
            margin: 0 -24px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 -8px;
        }
    }

    &.grid_minpx {
        margin: 0 -20px;
    }
}

.grid__col {
    &.grid__col_4x {
        @include media-breakpoint-up(lg) {
            @include fw(100%/12*3);
        }
    
        @include media-breakpoint-only(md) {
            @include fw(100%/12*4);
        }

        @include media-breakpoint-up(md) {
            @include offset(0 0 64px,0 24px);
        }
    
        @include media-breakpoint-down(sm) {
            @include fw(100%/12*6);
            @include offset(0 0 32px,0 8px);
        }
    }

    &.grid__col_3x {
        @include px(20px);
        margin: 0 0 64px;
    
        @include media-breakpoint-up(lg) {
            @include fw(100%/12*4);
        }
    
        @include media-breakpoint-between(sm,md) {
            @include fw(100%/12*6);
        }
    
        @include media-breakpoint-down(xs) {
            @include fw(100%);
        }
    }
}