@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/dmsans/DMSans-Regular.eot');
    src: local('DM Sans Regular'), local('DMSans-Regular'),
        url('../fonts/dmsans/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/dmsans/DMSans-Regular.woff2') format('woff2'),
        url('../fonts/dmsans/DMSans-Regular.woff') format('woff'),
        url('../fonts/dmsans/DMSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/dmsans/DMSans-Medium.eot');
    src: local('DM Sans Medium'), local('DMSans-Medium'),
        url('../fonts/dmsans/DMSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/dmsans/DMSans-Medium.woff2') format('woff2'),
        url('../fonts/dmsans/DMSans-Medium.woff') format('woff'),
        url('../fonts/dmsans/DMSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/dmsans/DMSans-Bold.eot');
    src: local('DM Sans Bold'), local('DMSans-Bold'),
        url('../fonts/dmsans/DMSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/dmsans/DMSans-Bold.woff2') format('woff2'),
        url('../fonts/dmsans/DMSans-Bold.woff') format('woff'),
        url('../fonts/dmsans/DMSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Impact';
	src: url('../fonts/impact/Impact.eot');
	src: local('Impact'),
		url('../fonts/impact/Impact.eot?#iefix') format('embedded-opentype'),
		url('../fonts/impact/Impact.woff') format('woff'),
		url('../fonts/impact/Impact.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
    font-display: swap;
}

body {
	font: $font-size_default $font-family_default;
	line-height: $line-height_default;
	color: $color__text_default;
    background: #000;
}

h1 {
    @extend %title-default;
}

a {
    @extend %link-default;

    &:hover,
    &:focus {
        @extend %link-default;
    }
}

input {
    outline: 0;
    outline: none;
}

button {
    outline: 0;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0;
        cursor: pointer;
    }
}

.border-radius {
    border-radius: 8px;
}

.btn {
    @include inline-block;
    padding: 6px 40px;
    font-weight: 700;
    color: $color__brand;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 8px;
    border: 2px solid $color__brand;
    background: rgba(0,0,0,0);
    transition: all .3s ease 0s;

    &:hover {
        box-shadow: 0 0 0 1px $color__brand inset;
    }

    &.btn_maxw {
        @include px(6px);
        width: 100%;
    }

    &.btn_minw {
        @include px(6px);
        width: 165px;
    }

    &.btn_minpx {        
        @include px(18px);
    }

    &.btn_noborder {
        border: 0;
        padding: 0;
        color: #fff;

        &:hover {
            box-shadow: none;
        }
    }
}

.icons {
    display: none;
}

.icon-fluid {
    @include size(100%,100%);
}

.icon-prev {
    @include size(18px,18px);
}

.overflow-hidden {
    overflow: hidden;
}

.close {
    @include inline-block;
    @include size(32px,32px);
    padding: 0;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    border: 0;
    background: rgba(0,0,0,0);
}

.divider {
    padding: 28px 0;
    background: #333;

    @include media-breakpoint-up(md) {
        margin: 0 0 80px;
    }

    @include media-breakpoint-down(sm) {
        margin: 0 0 56px;
    }
}

/* map */
.map {
    background: url(../img/bg/map.png) no-repeat scroll center top rgba(0,0,0,0);

    @include media-breakpoint-up(lg) {
        height: 680px;
        padding: 152px 0 0;
        margin: -152px 0 194px;
    }

    @include media-breakpoint-down(md) {
        height: 400px;
        padding: 92px 0 0;
        margin: -92px 0 48px;
        background-size: auto 100%;
    }
}

/* readmore */
.readmore-icon {
    @include fw(30px);
    @include size(30px,54px);
    color: $color__brand;
    margin: 0 0 0 18px;
    transition: all .3s ease 0s;
}

.readmore {
    @extend %d-flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
        .readmore-icon {
            color: #fff;
        }
    }
}

/* widget */
.yottie-widget-video,
.yottie-widget-feed-section-slide {
    background: none !important;
}

#yottie_1 {
    .yottie-widget-inner {
        .yottie-widget-contents + a {
            display: none !important;
        }
    }
}