.swiper-container {
    &.swiper-container_main {
        @include media-breakpoint-up(lg) {
            height: 416px;
            border-radius: 8px;
        }

        .swiper-slide {
            @include media-breakpoint-up(lg) {
                height: 100%;
                background-repeat: no-repeat;
                background-color: rgba(0,0,0,0);
                background-position: center center;
            }

            @include media-breakpoint-down(md) {
                @include px(4px);
                width: 375px !important;
                background-image: none !important;
            }
        }

        .img-fluid {
            @include media-breakpoint-up(lg) {
                visibility: hidden;
            }

            @include media-breakpoint-down(md) {
                border-radius: 8px;
            }
        }
    }

    &.swiper-container_thumbs {
        @include media-breakpoint-up(lg) {
            @include pos(0,0);
            @include size(160px,416px);
            padding: 0 28px 0 0;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        .swiper-slide {
            cursor: pointer;
            overflow: hidden;
            margin: 0 0 10px;
            height: auto !important;
            border-radius: 8px;
            border: 2px solid rgba(0,0,0,0);

            &.swiper-slide-thumb-active {
                border-color: $color__brand;
            }
        }

        .img-fluid {
            pointer-events: none;
        }
    }

    &.swiper-container_members {
        @include media-breakpoint-down(md) {
            @include mx(-24px);
        }
    }
}