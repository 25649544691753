.header {
    @extend %zi-2;
    z-index: 3;    
    background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );

    @include media-breakpoint-up(lg) {
        @include py(20px);
        margin-bottom: 32px;
    }

    @include media-breakpoint-down(md) {
        @include py(12px);
        margin-bottom: 12px;
    }
}

.header__wrapper {
    @extend %wrapper;
    @extend %d-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.header__logo {
    @extend %color-brand;

    @include media-breakpoint-up(lg) {
        @include fw(42px);
        @include size(42px,80px);
    }

    @include media-breakpoint-down(md) {
        @include fw(30px);
        @include size(30px,56px);
    }
}

.header__btn {
    @include fw(18px);
    @include size(18px,18px);
    border: 0;
    padding: 0;
    color: #fff;
    cursor: pointer;
    background: rgba(0,0,0,0);
    transition: all .3s ease 0s;

    &.header__btn_menu {
        height: 20px;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &.header__btn_search {
        &:hover {
            transform: scale(110%);
        }
    }
}