.result {
    margin: 0 0 48px;
}

.result__title {
    display: block;
    font-weight: 400;
    font-family: $font-family_title;

    @include media-breakpoint-up(lg) {
        @include fs-lh(36px,44px);
        margin: 0 0 10px;
    }

    @include media-breakpoint-down(md) {
        @include fs-lh(26px,32px);
        margin: 0 0 32px;
    }
}

.result__desc {
    margin: 0 0 18px;
    line-height: 21px;
    letter-spacing: 0.03em;
}