%wrapper {
    @include px(24px);
    @include mx(auto);
    max-width: 1328px;
    min-width: 320px;
}

%d-flex {
    display: flex;
    flex-wrap: wrap;
}

%link-default {
    text-decoration: none;
    color: $color__text_links;
}

%title-default {
    font-weight: 400;
    font-family: $font-family_title;

    @include media-breakpoint-up(xl) {
        font-size: 64px;
    }

    @include media-breakpoint-down(xl) {
        font-size: 48px;
    }
}

%text-default {
    font-size: $font-size_default;
    line-height: $line-height_content;
    letter-spacing: 0.03em;
}

%color-brand {
    color: $color__brand;

    &:hover,
    &:focus {
        color: $color__brand;
    }
}

%zi-1 {
    z-index: 1;
    position: relative;
}

%zi-2 {
    z-index: 2;
    position: relative;
}

.d-none {
    display: none !important;
}

.visible {
    visibility: visible !important;
}