.modal {
    &.modal_custom {
        .modal-dialog {
            @include media-breakpoint-up(md) {
                max-width: 594px;
            }

            @include media-breakpoint-down(xs) {
                max-width: 100%;
                margin: 0;
            }

            &.modal-dialog_sm {
                @include media-breakpoint-up(sm) {
                    max-width: 346px;
                }
        
                .modal-content {
                    @include media-breakpoint-up(sm) {
                        padding: 32px;
                    }
                }

                .modal-title {
                    width: 50%;

                    @include media-breakpoint-up(md) {
                        margin: 0 0 18px;
                    }

                    @include media-breakpoint-down(sm) {
                        margin: 0 0 45px;
                    }
                }

                .modal-bg {
                    @include size(240px,125px);
                }
            }
        }

        .modal-content {
            overflow: hidden;
            background: #333;
            box-shadow: 0 1px 8px 0 rgba(0,0,0,0);

            @include media-breakpoint-up(md) {
                padding: 32px 80px 40px;
            }

            @include media-breakpoint-down(sm) {
                padding: 32px 24px 40px;
            }

            @include media-breakpoint-up(sm) {
                border-radius: 16px;
            }

            @include media-breakpoint-down(xs) {
                border-radius: 0 0 16px 16px;
            }
        }

        .modal-header {
            @extend %zi-2;
            border: 0;
            padding: 0;

            .close {
                padding: 0;

                @include media-breakpoint-up(md) {
                    right: -32px;
                }

                @include media-breakpoint-down(sm) {
                    top: -8px;
                    right: 0;
                }
            }
        }

        .modal-title {
            font-weight: 400;
            font-family: $font-family_title;
            margin: 0 0 32px;

            @include media-breakpoint-up(md) {
                @include fs-lh(36px,44px);
            }

            @include media-breakpoint-down(sm) {
                @include fs-lh(24px,30px);
            }
        }

        .modal-body {
            @extend %zi-2;
            padding: 0;
        }
    }
}

.modal-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    @include media-breakpoint-up(md) {
        @include size(344px,244px);
    }

    @include media-breakpoint-down(sm) {
        @include size(220px,156px);
    }
}