$font-family_default: 'DM Sans', Arial, sans-serif;
$font-family_title: 'Impact', Arial, sans-serif;
$font-size_default: 16px;

$line-height_default: 1;
$line-height_content: 1.4;

$color__brand: #AFD31F;
$color__border: #E5E5E5;
$color__danger: #E75A1E;
$color__text_default: #fff;
$color__text_links: $color__text_default;