.footer {
    @extend %wrapper;
    @include py(24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(../img/bg/footer.jpg) no-repeat center top rgba(0,0,0,0);

    .menu {
        margin: 0 0 24px;
    }
}

.footer__logo {
    @extend %color-brand;
    margin: 0 0 24px;
    visibility: hidden;

    .icon-fluid {
        @include size(115px,220px);
    }
}