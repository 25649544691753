.pagination {
    @extend %d-flex;
    @include offset(0 0 80px,12px);
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #fff;

    &.pagination_nav {
        @include mx(20px);

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.pagination__inner {
    .pagination__item {
        display: none;

        &[class*="-active"] {
            display: flex;
        }
    }
}

.pagination__nav {
    @include fw(24px);
    @include size(24px,24px);

    &.pagination__nav_prev {
        margin: 0 24px 0 0;
    }

    &.pagination__nav_next {
        
    }
}

.pagination__item {
    @extend %d-flex;
    @include fw(40px);
    @include size(40px,40px);
    @include offset(0 24px 0 0,8px);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;

    &.pagination__item_current {
        
    }
}