.pos__picture {
    display: block;
    margin: 0 0 8px;
    overflow: hidden;
    border-radius: 8px;
}

.pos__title {
    display: block;
    margin: 0 0 16px;
    font-family: $font-family_title;

    @include media-breakpoint-up(md) {
        @include fs-lh(24px,$line-height_content);
    }

    @include media-breakpoint-down(sm) {
        @include fs-lh(18px,22px);
    }
}

.pos__price {
    @extend %d-flex;
    color: $color__brand;
    align-items: center;
    font-weight: bold;
    letter-spacing: 0.03em;
}

.pos__icon {
    @include fw(18px);
    @include size(18px,18px);
    margin: 0 8px 0 0;
}