.social {
    &.social_side {
        width: 18px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            display: none;
            height: 262px;
            position: absolute;
            right: 24px;
            top: 152px;
        }

        @include media-breakpoint-down(md) {
            display: flex;
        }

        .social__item {
            @include size(18px,18px);
            margin: 0 0 20px;

            &.social__item_yt {
                height: 16px;
            }
        }
    }
}

.social-visible {
    .social_side {
        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }
}

.social__item {
    @include inline-block;
    @include size(24px,24px);
    margin: 0 24px 0 0;

    &.social__item_yt {
        width: 36px;
    }

    &:last-child {
        margin-right: 0;
    }
}

.social__title {
    @include before;
    font-size: 12px;
    transform: rotate(-90deg);
    transform-origin: center;
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
        @include inline-block;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }

    &:before {
        @include size(48px,1px);
        top: 50%;
        right: -66px;
        background: #fff;
    }
}