.jumbotron {
    @extend %zi-1;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        margin: -152px 0 0;
    }

    @include media-breakpoint-down(md) {
        margin: -92px 0 0;
    }
}

.jumbotron__slide {
    overflow: hidden;
    background: #000;

    @include media-breakpoint-down(md) {
        height: auto !important;
    }
}

.jumbotron__picture {
    text-align: center;

    @include media-breakpoint-down(md) {
        @include pos(0,0);
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include media-breakpoint-only(xs) {
        transform: scale(250%);
    }
}

.jumbotron__video {
    @include media-breakpoint-up(up) {
        @include pos(50%,50%);
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        transform: translate(-50%,-50%);
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.jumbotron__outer {
    @extend %zi-2;

    @include media-breakpoint-up(xl) {
        padding: 152px 0 120px;
    }

    @include media-breakpoint-only(lg) {
        padding: 152px 0 50px;
    }

    @include media-breakpoint-up(lg) {
        @include pos(0,0);
        right: 0;
        bottom: 0;
        //padding: 152px 0 0;
    }

    @include media-breakpoint-down(md) {
        height: 100%;
        padding: 92px 0 50px;
    }
}

.jumbotron__entry {
    @extend %wrapper;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
    }
}

.jumbotron__header {
    @include media-breakpoint-down(md) {
        margin: 0 0 56px;
    }
}

.jumbotron__title {
    @extend %title-default;
    margin: 0 0 24px;

    @include media-breakpoint-up(md) {
        @include mw(100%/12*8);
    }

    @include media-breakpoint-down(sm) {
        @include mw(100%);
    }
}

.jumbotron__readmore {
    @include mw(100%);
}

.jumbotron__footer {
    @include py(12px,68px);
    opacity: 0;
    position: relative;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #fff;
    transition: all .4s ease .7s;

    @include media-breakpoint-up(lg) {
        @include mw(100%/12*6);
    }

    @include media-breakpoint-only(md) {
        @include mw(100%/12*8);
    }

    @include media-breakpoint-up(md) {
        @include px(40px);
        @include mx(auto);
    }

    @include media-breakpoint-down(sm) {
        @include px(20px);
        @include mx(-16px);
    }
}

.swiper-slide-active {
    .jumbotron__footer {
        opacity: 1;
    }
}

.jumbotron__current {
    @extend %d-flex;
    @include fw(40px);
    @include size(40px,40px);
    @include offset(0 0 0 -20px,8px);
    position: absolute;
    left: 50%;
    bottom: 12px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
}

.jumbotron__desc {
    @extend %text-default;
}

.jumbotron__nav {
    @extend %d-flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 22px;
    z-index: 3;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(xl) {
        bottom: 142px;
    }

    @include media-breakpoint-down(lg) {
        bottom: 72px;
    }
}

.jumbotron__btn {
    @include fw(24px);
    @include size(24px,24px);
    @include offset(0 44px,0);
    border: 0;
    cursor: pointer;
    color: #fff;
    background: rgba(0,0,0,0);
}