.search {
    @include media-breakpoint-up(lg) {
        @include size(70%,32px);
        @include offset(-16px 0 0,0 32px 0 0);
        position: absolute;
        top: 50%;
        right: 24px;
        display: none;
        align-items: center;
    }

    @include media-breakpoint-down(md) {
        @include size(344px,460px);
        padding: 100px 24px 24px;
        position: fixed;
        top: 0;
        right: 0;
        overflow: hidden;
        background: #333;
        border-radius: 0 0 0 16px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
        transform: translateX(100%);
        transition: all 0.5s ease-in-out;
    }

    .close {
        position: absolute;

        @include media-breakpoint-up(lg) {
            top: 0;
            right: 0;
        }

        @include media-breakpoint-down(md) {
            top: 24px;
            right: 24px;
        }
    }
}

.search-visible {
    .search {
        @include media-breakpoint-up(lg) {
            display: flex;
        }

        @include media-breakpoint-down(md) {
            transform: translateX(0);
        }
    }

    .menu__wrapper {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .header__btn {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.search__col {
    @include media-breakpoint-up(lg) {
        padding: 0 12px 0 0;
    }

    &.search__col_lg {
        @include media-breakpoint-up(lg) {
            @include fw(100%/4*3);
        }

        @include media-breakpoint-down(md) {
            @include fw(100%);
        }
    }

    &.search__col_sm {
        @include media-breakpoint-up(lg) {
            @include fw(100%/4);
        }

        @include media-breakpoint-down(md) {
            @include fw(100%/12*6);
            margin: 0 0 32px;
            margin-left: (100%/13*3);
        }
    }
}

.search__input {
    position: relative;
    padding: 0 30px 0 0;
    border-radius: 8px;
    border: 1px solid $color__border;
    transition: all .3s ease 0s;

    &:hover {
        border-color: #fff;
    }

    @include media-breakpoint-down(md) {
        margin: 0 0 32px;
    }
}

.search__field {
    @include size(100%,100%);
    padding: 5px 0 5px 8px;
    border: 0;
    color: $color__border;
    letter-spacing: 0.05em;
    background: rgba(0,0,0,0);

    &::-webkit-input-placeholder {
        font-size: 12px;
        color: $color__border;
    }
      
    &::-moz-placeholder {
        font-size: 12px;
        color: $color__border;
    }

    &:-ms-input-placeholder {
        font-size: 12px;
        color: $color__border;
    }

    &:-moz-placeholder {
        font-size: 12px;
        color: $color__border;
    }

    @include media-breakpoint-down(md) {
        text-align: center;
    }
}

.search__close {
    @include size(18px,18px);
    display: none;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 6px;
    border: 0;
    padding: 0;
    background: rgba(0,0,0,0);

    .icon-fluid {
        pointer-events: none;
    }
}

.search__footer {
    @include media-breakpoint-up(lg) {
        display: none;
    }

    @include media-breakpoint-down(md) {
        @include size(136px,200px);
        position: absolute;
        left: 0;
        bottom: 0;
    }
}