.bottom {
    @include media-breakpoint-up(md) {
        margin-bottom: 80px;
    }

    &.bottom_grid {
        @extend %d-flex;
        @include mx(-20px);
        align-items: center;
    }
    
    &.bottom_bg {
        @include offset(0,40px 0);
        background: #333;
    }

    &.bottom_slides {
        margin: 0;

        @include media-breakpoint-up(lg) {
            flex-wrap: wrap;
            align-items: center;
        }

        .bottom__header {
            @include media-breakpoint-up(lg) {
                @include mx(0,(100%/12));
                margin-bottom: 86px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &.bottom_mobile {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.bottom__outer {
    @extend %wrapper;

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
    }

    @include media-breakpoint-down(xs) {
        overflow: hidden;
    }

    .bottom__header {
        @extend %d-flex;
        @include px(0);
        margin-left: 0;
        flex-direction: column;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            @include fw(100%/12*4);
        }
    }

    .bottom__title {
        @include media-breakpoint-down(md) {
            margin: 0 0 90px;
        }
    }
}

.bottom__header {
    @include px(20px);

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*3);
        margin-left: (100%/12);
    }

    @include media-breakpoint-down(md) {
        @include fw(100%);
        margin: 0 0 30px;
    }

    &.bottom__header_wide {
        margin-left: 0;

        @include media-breakpoint-only(lg) {
            @include fw(100%);
        }

        @include media-breakpoint-down(lg) {
            margin-bottom: 48px;
        }

        .bottom__title {
            margin-bottom: 0;
        }
    }
}

.bottom__title {
    @extend %title-default;

    @include media-breakpoint-up(lg) {
        margin: 0 0 14px;
    }

    @include media-breakpoint-down(md) {
        margin: 0 0 32px;
    }

    &.bottom__title_subtitle {
        font-size: 36px;
    }
}

.bottom__subtitle {
    @include fs-lh(24px,30px);
    margin: 0 0 16px;
    font-weight: 400;
    font-family: $font-family_title;
}

.bottom__desc {
    @extend %text-default;

    @include media-breakpoint-up(lg) {
        margin: 0 0 14px;
    }

    @include media-breakpoint-down(md) {
        margin: 0 0 78px;
    }
}

.bottom__col {
    @include px(20px);

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*4);
    }

    @include media-breakpoint-only(md) {
        @include fw(100%/12*6);
    }

    @include media-breakpoint-down(sm) {
        @include fw(100%);
        margin: 0 0 32px;
    }

    &.bottom__col_sm {
        @include media-breakpoint-up(xl) {
            @include fw(100%/12*2);
            margin: 0 0 0 (100%/12);
        }

        @include media-breakpoint-up(lg) {
            @include before;
        }

        @include media-breakpoint-only(lg) {
            @include fw(100%/12*3);
            margin: 0 (100%/12) 0 0;
        }

        @include media-breakpoint-down(md) {
            @include fw(100%);
            margin: 0 0 56px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &:before {
            @include size(1px,48px);
            top: 50%;
            right: -48px;
            margin: -24px 0 0;
            background: #fff;
        }

        &:last-of-type {
            &:before {
                display: none;
            }
        }

        .bottom__desc {
            margin-bottom: 0;
        }
    }
}

.bottom__picture {
    @include px(0);
    position: relative;

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*7);
        margin-left: (100%/12);
    }

    @include media-breakpoint-down(md) {
        margin: 0 -24px;
        text-align: center;
    }

    @include media-breakpoint-down(xs) {
        margin: 0 -100%;
    }

    .img-fluid {
        @include media-breakpoint-up(lg) {
            border-radius: 0 0 0 24px;
        }
    }
}

.bottom__logo {
    @include media-breakpoint-up(lg) { 
        @include pos(-20px,-30px);
        @include size(290px,210px);
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}