.logogrid {
    @extend %d-flex;
    @include mx(-20px);
}

.logogrid__item {
    padding: 0 20px;
    text-align: center;

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*3);
        margin: 0 0 40px;
    }

    @include media-breakpoint-down(md) {
        margin: 0 0 12px;
    }

    @include media-breakpoint-only(md) {
        @include fw(100%/12*4);
    }

    @include media-breakpoint-down(sm) {
        @include fw(100%/2);
    }
}