.adcard {
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
    background: #000;
    border: 1px solid #333;
    transition: all .3s ease 0s;

    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 8px;
    }

    @include media-breakpoint-down(sm) {
        margin: 0 0 32px;
    }

    &:hover {
        background: #333;
    }

    &.adcard_wrapper {
        @extend %wrapper;
        border: 0;
        overflow: visible;
        align-items: stretch;
        margin-bottom: 32px;

        &:hover {
            background: rgba(0,0,0,0);
        }

        @include media-breakpoint-down(md) {
            display: block;
        }

        .adcard__title {
            line-height: 1;
        }

        .adcard__meta {
            @include fw(100%);
            @include offset(0 0 20px,0);
            display: block;
        }

        .adcard__desc {
            @include fw(100%);
            @include offset(0 0 20px,0);
        }
    }
}

.adcard__gallery {
    margin: 0 0 32px;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*7);
        padding: 0 36px 0 160px;
    }

    @include media-breakpoint-down(md) {
        @include mx(-24px);
    }

    &.adcard__gallery_mr {
        @include media-breakpoint-up(lg) {
            @include fw(100%/12*6);
            margin-right: (100%/12);
        }
    }
}

.adcard__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*5);
        margin: 0 0 32px;
    }

    @include media-breakpoint-down(md) {
        margin: 0 0 46px;
    }
}

.adcard__picture {
    @include media-breakpoint-up(xl) {
        @include fw(100%/12*2);
    }

    @include media-breakpoint-up(lg) {
        padding: 0 24px 0 0;
    }

    @include media-breakpoint-only(lg) {
        @include fw(100%/12*3);
    }

    @include media-breakpoint-only(md) {
        @include fw(100%/12*6);
    }

    @include media-breakpoint-down(md) {
        margin: 0 0 10px;
    }

    @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;
    }
}

.adcard__meta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;

    @include media-breakpoint-between(lg,xl) {
        @include fw(100%/12*3);
    }

    @include media-breakpoint-up(lg) {
        padding: 12px 24px 12px 0;
    }

    @include media-breakpoint-only(md) {
        @include fw(100%/12*6);
    }

    @include media-breakpoint-down(md) {
        @include offset(0 0 24px,0 16px);
    }
}

.adcard__title {
    @include fs-lh(24px,28px);
    display: block;
    font-family: $font-family_title;

    @include media-breakpoint-up(md) {
        margin: 0 0 24px;
    }

    @include media-breakpoint-down(sm) {
        margin: 0 0 10px;
    }
}

.adcard__link {
    @extend %d-flex;
    @extend %color-brand;
    align-items: center;
    margin: 0 0 6px;
    letter-spacing: 0.03em;
}

.adcard__price {
    @extend %d-flex;
    @extend %color-brand;
    align-items: center;
    font-weight: bold;
    letter-spacing: 0.03em;
}

.adcard__icon {
    @include fw(18px);
    @include size(18px,18px);
    margin: 0 8px 0 0;
}

.adcard__desc {
    @extend %text-default;

    @include media-breakpoint-between(lg,xl) {
        @include fw(100%/12*6);
    }

    @include media-breakpoint-up(lg) {
        padding: 12px 24px 12px 0;
    }

    @include media-breakpoint-only(md) {
        @include fw(100%);
    }

    @include media-breakpoint-down(md) {
        @include offset(0 0 34px,0 16px);
    }
}

.adcard__nav {
    @include media-breakpoint-up(lg) {
        @include fw(100%/12*6);
        text-align: right;
    }

    @include media-breakpoint-down(md) {
        text-align: center;
    }
}

.adcard__nav-btn {
    @include font(12px,500);
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    text-transform: uppercase;
}

.adcard__nav-icon {
    @include fw(18px);
    @include size(18px,18px);
    margin: 0 10px 0 0;
}