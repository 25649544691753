.content {
    margin: 0 0 80px;

    h2 {
        font-size: 36px;
        margin: 0 0 40px;
        font-family: $font-family_title;
    }

    p {
        @extend %text-default;
    }

    ul {
        @include list-unstyled();

        &.row {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-wrap: wrap;
            }

            li {
                margin-bottom: 60px;

                @include media-breakpoint-up(md) {
                    @include fw(100%/12*5);
                    margin-left: (100%/12);
                }
            }
        }
    }
}