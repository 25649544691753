.about {
    @include before;
    margin: 0 0 48px;

    &:before {
        @include media-breakpoint-up(lg) {
            @include pos(0,(100%/12*5));
            bottom: 0;
            width: 1px;
            background: #fff;
        }
    }
}

.about__row {
    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.about__picture {
    @include before;
    margin: 0 0 32px;

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*5);
        padding: 0 0 0 (100%/12);
    }

    @include media-breakpoint-down(md) {
        text-align: center;
    }

    &:before {
        @include pos(50%,50%);
        @include size(380px,380px);
        margin: -190px 0 0 -190px;
        z-index: 1;
        border-radius: 50%;
        background: rgb(51,51,51);
        background: -moz-radial-gradient(center, ellipse cover,  rgba(51,51,51,1) 0%, rgba(0,0,0,1) 70%, rgba(0,0,0,1) 100%);
        background: -webkit-radial-gradient(center, ellipse cover,  rgba(51,51,51,1) 0%,rgba(0,0,0,1) 70%,rgba(0,0,0,1) 100%);
        background: radial-gradient(ellipse at center,  rgba(51,51,51,1) 0%,rgba(0,0,0,1) 70%,rgba(0,0,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#000000',GradientType=1 );
                    
    }

    .img-fluid {
        @extend %zi-2;
    }
}

.about__content {
    @include before;
    margin: 0 0 32px;

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*6);
        padding: 0 0 0 (100%/24);
    }

    &:before {
        @include media-breakpoint-up(lg) {
            @include pos(12px,-8px);
            @include size(16px,16px);
            border-radius: 50%;
            background: #fff;
        }
    }
}

.about__title {
    font-weight: 400;
    font-family: $font-family_title;

    @include media-breakpoint-up(lg) {
        @include fs-lh(36px,44px);
        margin: 0 0 10px;
    }

    @include media-breakpoint-down(md) {
        @include fs-lh(26px,32px);
        margin: 0 0 32px;
    }
}

.about__desc {
    line-height: 21px;
    letter-spacing: 0.03em;
}