.article__header {
    position: relative;

    @include media-breakpoint-up(md) {
        margin: 0 0 80px;
    }

    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        margin: 0 0 42px;
    }
}

.article__header-bg {
    text-align: right;

    @include media-breakpoint-down(md) {
        padding: 0 0 0 24px;
    }

    @include media-breakpoint-down(sm) {
        order: 2;
    }

    .img-fluid {
        border-radius: 8px 0 0 8px;
    }
}

.article__header-outer {
    @include media-breakpoint-up(md) {
        @include pos(0,0);
        right: 0;
        bottom: 0;
    }

    @include media-breakpoint-down(sm) {
        order: 1;
        margin: 0 0 24px;
    }
}

.article__header-entry {
    @extend %wrapper;
    @extend %d-flex;
    align-items: center;
    height: 100%;
}

.article__title {
    @include media-breakpoint-up(lg) {
        @include fw(100%/12*5);
    }

    @include media-breakpoint-only(md) {
        @include fw(100%/12*8);
    }

    @include media-breakpoint-up(md) {
        margin-left: (100%/12);
    }
}

.article-post__picture {
    @include inline-block;
}

.article-post__title {
    @include offset(-16px 0 0,0 0 0 24px);
    display: block;
    font-family: $font-family_title;

    @include media-breakpoint-up(md) {
        @include fs-lh(24px,$line-height_content);
    }

    @include media-breakpoint-down(sm) {
        @include fs-lh(18px,22px);
    }
}