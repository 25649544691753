.gallery {
    margin: 0 -20px;

    &.gallery_flex {
        @extend %d-flex;
    }
}

.gallery__wrapper {
    height: 100%;

    @include media-breakpoint-up(lg) {
        max-height: 900px;
    }

    @include media-breakpoint-only(md) {
        max-height: 640px;
    }
}

.gallery__item {
    position: relative;
    text-align: center;

    @include media-breakpoint-up(md) {
        @include fw(100%/3);
        @include offset(0 0 40px,0 20px);
    }

    @include media-breakpoint-only(sm) {
        @include fw(100%/2);
    }

    @include media-breakpoint-down(sm) {
        @include offset(0 0 32px,0 4px);
    }

    @include media-breakpoint-down(xs) {
        @include fw(100%);
    }

    &.gallery__item_slide {        
        @include media-breakpoint-up(lg) {
            height: (100%/3);
        }
        
        @include media-breakpoint-up(md) {
            @include fw(100%);
        }

        @include media-breakpoint-only(md) {
            height: (100%/2);
        }
        
        @include media-breakpoint-down(sm) {
            @include fw(327px);
        }
    }
}

.gallery__icon {
    &.gallery__icon_zoom {
        @include pos(50%,50%);
        @include size(120px,120px);
        margin: -60px 0 0 -60px;
    }
}