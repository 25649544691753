.blockquote {
    position: relative;
    margin-bottom: 80px;

    @include media-breakpoint-up(lg) {
        width: (100%/12*10);
        margin-left: (100%/12);
    }

    @include media-breakpoint-up(md) {
        @include px(100%/12);
        padding-top: 16px;
    }
}

.blockquote__icon {
    position: absolute;

    @include media-breakpoint-up(md) {
        @include size(150px,125px);
        top: 0;
    }

    @include media-breakpoint-down(sm) {
        @include size(108px,90px);
        top: 16px;
    }

    &.blockquote__icon_left {
        @include media-breakpoint-up(xl) {
            left: 50px;
        }

        @include media-breakpoint-between(md,lg) {
            left: 0;
        }

        @include media-breakpoint-down(sm) {
            left: -50px;
        }
    }

    &.blockquote__icon_right {
        @include media-breakpoint-up(xl) {
            right: 50px;
        }

        @include media-breakpoint-between(md,lg) {
            right: 0;
        }

        @include media-breakpoint-down(sm) {
            right: -50px;
        }
    }
}

.blockquote__title {
    font-style: italic;
    text-align: center;

    @include media-breakpoint-up(md) {
        @include fs-lh(36px,46px);
        margin: 0 0 32px;
    }

    @include media-breakpoint-down(sm) {
        @include fs-lh(24px,32px);
        margin: 0 0 18px;
    }
}

.blockquote__footer {
    font-style: italic;
    text-align: right;

    @include media-breakpoint-up(md) {
        @include fs-lh(24px,32px);
    }

    @include media-breakpoint-down(sm) {
        @include fs-lh(18px,24px);
    }
}