.fancybox-button {
    opacity: 1 !important;
    display: none !important;
    visibility: visible !important;
    background: none !important;

    &.fancybox-button--close {
        @include size(32px,32px);
        padding: 4px !important;
        border-radius: 50%;
        border: 1px solid #fff;
        display: block !important;
    }

    &.fancybox-button--arrow_left {
        display: inline-block !important;
    }

    &.fancybox-button--arrow_right {
        display: inline-block !important;
    }

    div {
        @include size(40px,40px);
        border-radius: 50%;
        border: 1px solid #fff;
    }
}

.fancybox-infobar {
    display: none !important;
}

.fancybox-toolbar {
    padding: 8px;
    opacity: 1 !important;
    visibility: visible !important;
}

.fancybox-is-open {
    .fancybox-bg {
        opacity: 1 !important;

        @include media-breakpoint-up(md) {
            background: rgba(0,0,0,0.5) !important;
        }
    
        @include media-breakpoint-down(sm) {
            background: #000 !important;
        }
    }
}

.fancybox-stage {    
    @include media-breakpoint-down(md) {
        left: 24px !important;
        right: 24px !important;
    }
}

.fancybox-image {
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
}