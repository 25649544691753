.form {
    &.form_grid {
        @extend %d-flex;
        justify-content: center;
    }
}

.form__item {
    @include offset(0 0 18px,0 20px);
    text-align: center;
    position: relative;

    @include media-breakpoint-up(lg) {
        @include fw(100%/12*3);
    }

    @include media-breakpoint-only(md) {
        @include fw(100%/12*4);
    }

    @include media-breakpoint-down(sm) {
        @include fw(100%);
    }

    &.form__item_wide {
        @include px(0);
        @include fw(100%);

        .form__label {
            left: 0;
            right: 0;
        }

        .form__field {
            &:focus,
            &.active {
                & + .form__label {
                    left: 15px;
                }
            }
        }
    }
}

.form__field {
    width: 100%;
    padding: 6px 8px;
    color: $color__border;
    text-align: center;
    border-radius: 8px;
    letter-spacing: 0.05em;
    border: 1px solid $color__border;
    background: rgba(0,0,0,0);
    transition: all .3s ease 0s;

    //font-size: 12px;

    &:hover {
        color: #fff;
        border-color: #fff;

        & + .form__label {
            color: #fff;
        }
    }

    &:focus,
    &.active {
        color: #fff;
        border-color: $color__brand;

        & + .form__label {
            top: -9px;
            left: 35px;
            right: auto;
            bottom: auto;
            color: #fff;
            padding: 1px 5px;
            display: inline-block;
            vertical-align: top;
            background: #000;

            &.form__label_light {
                background: #333;
            }
        }
    }

    &.error {
        border-color: $color__danger;
    }

    &.form__field_textarea {
        resize: none;

        @include media-breakpoint-up(md) {
            height: 80px;
        }

        @include media-breakpoint-down(sm) {
            height: 135px;
            margin: 0 0 32px;
        }
    }
}

.form__label {
    @include fs-lh(12px,16px);
    @include offset(0,8px);
    @include pos(1px,20px);
    bottom: 1px;
    right: 20px;
    display: block;
    text-align: center;
    letter-spacing: 0.05em;
    pointer-events: none;
    color: $color__border;
    transition: all .3s ease 0s;
}

.form__footer {
    @extend %d-flex;
    align-items: center;
    justify-content: space-between;
}