.section {
    @extend %wrapper;

    @include media-breakpoint-up(md) {
        margin-bottom: 80px;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 56px;
    }

    &.section_nomb {
        margin-bottom: 0;
    }

    &.section_404 {
        position: relative;

        @include media-breakpoint-up(lg) {
            margin-top: -120px;
        }

        @include media-breakpoint-between(sm,md) {
            margin-top: -80px;
        }

        h1 {
            @include media-breakpoint-up(md) {
                margin-bottom: 72px;
            }
    
            @include media-breakpoint-down(sm) {
                margin-bottom: 32px;
            }
        }
        
        .section__title {
            @include media-breakpoint-up(xl) {
                bottom: 64px;
                justify-content: end;
            }

            @include media-breakpoint-down(lg) {
                bottom: 0;
            }

            @include media-breakpoint-between(md,lg) {
                justify-content: center;
            }

            @include media-breakpoint-only(sm) {
                justify-content: end;
            }

            @include media-breakpoint-up(sm) {
                @include pos(0,0);
                left: 0;
                right: 0;
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.section__header {
    @extend %d-flex;
    align-items: center;

    @include media-breakpoint-up(md) {
        margin: 0 0 40px;
    }

    @include media-breakpoint-down(sm) {
        margin: 0 0 24px;
    }

    &.section__header_plain {
        display: block;
        text-align: center;
        margin: 0 0 24px;

        .section__title {
            @include offset(0,0);
            border: 0;
            margin: 0 0 8px;
        }

        .section__intro {
            @include fw(100%);
            padding: 0;
            border: 0;
        }
    }
}

.section__title {
    @extend %title-default;
    order: 1;

    @include media-breakpoint-down(sm) {
        margin: 0 0 10px;
    }

    &.section__title_center {
        @include fw(100%);
        text-align: center;
    }

    &.section__title_subtitle {
        font-size: 36px;

        @include media-breakpoint-down(sm) {
            line-height: 44px;
        }
    }
}

.section__subtitle {
    @include fs-lh(12px,16px);
	font: $font-size_default $font-family_default;
    font-weight: 500;
    text-transform: uppercase;

    &.section__subtitle_flex {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-prev {
            @include fw(18px);
            margin: 0 10px 0 0;
        }
    }
}

.section__intro {
    @extend %text-default;
    order: 2;

    @include media-breakpoint-up(md) {
        @include fw(100%/12*4);
    }

    @include media-breakpoint-down(sm) {
        @include border(0 0 0 1px,#fff);
        padding: 0 0 0 16px;
    }

    & + .section__title {
        @include media-breakpoint-up(md) {
            @include fw(auto);
            @include border(0 1px 0 0,#fff);
            @include offset(0 40px 0 0,0 40px 0 0);
        }
    }
}